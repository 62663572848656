<template>
  <div>
    <div>
      <v-card
        height="60px"
        width="100%"
        flat
        class="d-flex align-center"
        style="background: rgba(127, 145, 155, 0.052607)"
      >
        <v-spacer></v-spacer>
        <download-csv class="btn btn-default" :data="filteredPendingVendors">
          <v-hover v-slot="{ hover }">
            <v-btn
              outlined
              @click="alertCSVDownload"
              color="primary"
              class="my-4 export-btn mr-9 hover-btn"
              :style="{
                'background-color': hover ? '#2bd5ae' : '',
                border: hover ? 'none' : '',
              }"
              elevation="2"
              ><span class="material-icons pr-1"> import_export </span
              ><span
                class="primary--text"
                style="
                  font-family: Inter;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 12px;
                  text-align: center;
                  letter-spacing: 0.636364px;
                "
                :style="{
                  fontSize: `${
                    $vuetify.breakpoint.mdAndDown ? '9  px' : '11px'
                  }`,
                }"
                >Export</span
              >
            </v-btn>
          </v-hover>
        </download-csv>
      </v-card>
    </div>
    <v-skeleton-loader type="table" :loading="isLoading">
      <v-layout
        row
        wrap
        class="align-center pb-4 py-6 px-8 text-body-2"
        v-if="$vuetify.breakpoint.mdAndUp"
      >
        <v-flex md1>
          <div class="d-flex align-center justify-center pa-0 ma-0">
            <p class="mb-0 pa-0 primary--text font-weight-bold pr-3">#</p>
          </div>
        </v-flex>
        <v-flex md2>
          <div class="d-flex align-center pa-0 ma-0">
            <p class="mb-0 pa-0 primary--text font-weight-bold">vendor</p>
          </div>
        </v-flex>
        <v-flex md2 class="d-flex flex-row">
          <div class="d-flex align-center">
            <p class="mb-0 primary--text font-weight-bold">Ref</p>
          </div>
        </v-flex>
        <v-flex md2>
          <div class="d-flex align-center">
            <p class="mb-0 primary--text font-weight-bold">Open Balance</p>
          </div>
        </v-flex>
        <v-flex md3>
          <div class="d-flex align-center">
            <p class="mb-0 primary--text font-weight-bold">Email</p>
          </div>
        </v-flex>
        <v-flex md2>
          <div class="d-flex justify-center align-center">
            <p class="mb-0 pl-md-8 primary--text font-weight-bold">Actions</p>
          </div>
        </v-flex>
      </v-layout>

      <v-row v-if="filteredPendingVendors && filteredPendingVendors.length > 0">
        <v-col
          cols="12"
          v-for="(vendor, index) in displayedItems"
          :key="index"
          class="py-0 ma-0"
        >
          <v-skeleton-loader
            :loading="isLoading"
            type="table"
            v-if="$vuetify.breakpoint.mdAndUp"
          >
            <VendorTable
              :index="index + 1"
              :id="vendor.id"
              :vendorCategory="vendor.WHT ? vendor.WHT.category : 'N/A'"
              :vendorname="vendor.vendorname"
              :hyphnId="vendor.hypn_id"
              :email="vendor.email"
            />
          </v-skeleton-loader>
          <!-- Data table for mobile -->
          <v-template v-if="$vuetify.breakpoint.smAndDown">
            <v-skeleton-loader :loading="isLoading" type="card">
              <vendorTableCard
                :index="vendor.index"
                :id="vendor.id"
                :vendorCategory="vendor.WHT ? vendor.WHT.category : 'N/A'"
                :vendorname="vendor.vendorname"
                :email="vendor.email"
                :phone="vendor.phone"
                :hyphnId="vendor.hypn_id"
              />
            </v-skeleton-loader>
          </v-template>
        </v-col>
      </v-row>

      <!-- display a no data info if there are no data -->
      <div class="d-flex justify-center align-center pt-6" v-else>
        <span class="text--primary text-h5 font-monospace"
          >No Data available</span
        >
      </div>
      <!-- pagination component -->
      <div
        class="pagination__container px-8"
        v-if="filteredPendingVendors && filteredPendingVendors.length > 0"
      >
        <BasePagination
          :current-page="currentPage"
          :visible-pages="visiblePageValue"
          :total-pages="totalPages"
          @page-changed="handlePageChange"
        />
      </div>
    </v-skeleton-loader>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VendorTable from "./VendorTable.vue";
import vendorTableCard from "./vendorTableCard.vue";
import BasePagination from "@/components/BasePagination.vue";
import pagination from "@/mixins/pagination";
export default {
  name: "pendingVendors",
  components: {
    VendorTable,
    vendorTableCard,
    BasePagination,
  },
  mixins: [pagination],
  data() {
    return {
      isLoading: false,
      autoPay: false,
      amount: "N2,300,000",
      Ref: "#EXP084492",
      Payee: "Emmanuel John",
      Subtotal: "172500",
      dragging: false,
      dialog: false,
      searchQuery: "",
      itemsPerPage: 10,
    };
  },
  methods: {
    closeModal() {
      this.dialog = false;
      // this.workflowName = e;
      // console.log(e);
    },
    onDroppedFiles($event) {
      this.dragging = false;
      let files = [...$event.dataTransfer.items]
        .filter((item) => item.kind === "file")
        .map((item) => item.getAsFile());

      console.table(files);
    },
    setSearchText(value) {
      this.searchQuery = value;
    },
    alertCSVDownload() {
      this.showToast({
        sclass: "success",
        show: true,
        message: "CSV downloaded!",
        timeout: 1000,
      });
    },
  },
  computed: {
    ...mapGetters({
      pendingVendors: "contacts/PendingVendors",
    }),
    filteredPendingVendors() {
      if (this.searchQuery) {
        return this.pendingVendors.filter((vendor) => {
          return this.searchQuery
            .toLowerCase()
            .split(" ")
            .every((v) => {
              return (
                vendor.vendorname?.toLowerCase().includes(v) ||
                vendor.email?.toLowerCase().includes(v) ||
                vendor.phone?.toLowerCase().includes(v) ||
                vendor.hypn_id?.toLowerCase().includes(v)
              );
            });
        });
      } else return this.pendingVendors;
    },

    // get the length of the filtered form array to use it as the value of the visible page
    visiblePageValue() {
      const pageNumber = Math.floor(
        this.filteredPendingVendors.length / this.itemsPerPage
      );
      return pageNumber > 5 ? 5 : pageNumber < 1 ? 1 : pageNumber;
    },

    // get array per page
    displayedItems() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      if (
        this.filteredPendingVendors &&
        this.filteredPendingVendors.length > this.itemsPerPage
      ) {
        return this.filteredPendingVendors.slice(startIndex, endIndex);
      } else {
        return this.filteredPendingVendors.slice();
      }
    },
    totalPages() {
      return Math.ceil(this.filteredPendingVendors.length / this.itemsPerPage);
    },
  },
  mounted() {
    this.isLoading = true;
    setTimeout(
      () => {
        this.isLoading = false;
      },
      3000,
      this.pendingVendors
    );
    try {
      return this.$store.dispatch("contacts/fetchAllVendors");
    } catch (error) {
      console.log(JSON.stringify(error, null, 2));
    }
  },
};
</script>

<style scoped>
.switch-card {
  height: 53px;
  background: rgba(127, 145, 155, 0.052607);
}

.switch {
  width: 15.28px;
  height: 15.28px;
}

.export-btn {
  font-family: "Inter" sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  letter-spacing: 0.636364px;

  color: #19283d;
}

i.sli-font {
  font-size: 12px;
  display: inline-block;
}

.material-icons {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  color: #19283d;
}

.pagination__container {
  display: flex;
  justify-content: right;
  align-items: center;
  padding-top: 6px;
}
</style>
