<template>
  <div>
    <div>
      <v-card
        height="60px"
        width="100%"
        flat
        class="d-flex align-center"
        style="background: rgba(127, 145, 155, 0.052607)"
      >
        <v-spacer></v-spacer>
        <download-csv class="btn btn-default" :data="filteredVendors">
          <v-hover v-slot="{ hover }">
            <v-btn
              outlined
              @click="alertCSVDownload"
              color="primary"
              class="my-4 export-btn mr-9 hover-btn"
              :style="{
                'background-color': hover ? '#2bd5ae' : '',
                border: hover ? 'none' : '',
              }"
              elevation="2"
            >
              <span class="material-icons pr-1"> import_export </span>
              <span
                class="primary--text"
                style="
                  font-family: Inter;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 12px;
                  text-align: center;
                  letter-spacing: 0.636364px;
                "
                :style="{
                  fontSize: `${
                    $vuetify.breakpoint.mdAndDown ? '9  px' : '11px'
                  }`,
                }"
              >
                Export
              </span>
            </v-btn>
          </v-hover>
        </download-csv>
      </v-card>
    </div>
    <v-skeleton-loader type="table" :loading="loading">
      <v-layout
        row
        wrap
        class="align-center pb-4 py-6 px-8 text-body-2"
        v-if="$vuetify.breakpoint.mdAndUp"
      >
        <v-flex md1>
          <div class="d-flex align-center justify-center pa-0 ma-0">
            <p class="mb-0 pa-0 primary--text font-weight-bold pr-3">#</p>
          </div>
        </v-flex>
        <v-flex md2>
          <div class="d-flex align-center pa-0 ma-0">
            <p class="mb-0 pa-0 primary--text font-weight-bold">contractor</p>
          </div>
        </v-flex>
        <v-flex md2 class="d-flex flex-row">
          <div class="d-flex align-center pl-2">
            <p class="mb-0 primary--text font-weight-bold">Ref</p>
          </div>
        </v-flex>
        <v-flex md2>
          <div class="d-flex align-center">
            <p class="mb-0 primary--text font-weight-bold">Category</p>
          </div>
        </v-flex>
        <v-flex md2>
          <div class="d-flex align-center">
            <p class="mb-0 primary--text font-weight-bold">Email</p>
          </div>
        </v-flex>
        <v-flex md1>
          <div class="d-flex align-center pl-2">
            <p class="mb-0 primary--text font-weight-bold">Created</p>
          </div>
        </v-flex>
        <v-flex md2>
          <div class="d-flex justify-center align-center">
            <p class="mb-0 pl-md-8 primary--text font-weight-bold">Actions</p>
          </div>
        </v-flex>
      </v-layout>

      <v-row v-if="filteredVendors && filteredVendors.length > 0">
        <v-col
          cols="12"
          v-for="(vendor, index) in displayedItems"
          :key="index"
          class="py-0 ma-0"
        >
          <VendorTable
            v-if="$vuetify.breakpoint.mdAndUp"
            :index="index + 1"
            :id="vendor.id"
            :hyphnId="vendor.hypn_id || 'N/A'"
            :vendorname="vendor.vendorname || 'N/A'"
            :vendorCategory="vendor.WHT ? vendor.WHT.category : 'N/A'"
            :email="vendor.email || 'N/A'"
            :createdAt="vendor.created_at | moment('DD/MM/YYYY')"
          />
          <!-- Data table for mobile -->
          <vendorTableCard
            v-if="$vuetify.breakpoint.smAndDown"
            :index="vendor.index"
            :id="vendor.id"
            :hyphnId="vendor.hypn_id || 'N/A'"
            :vendorname="vendor.vendorname || 'N/A'"
            :createdAt="vendor.created_at | moment('DD/MM/YYYY')"
            :email="vendor.email || 'N/A'"
            :phone="vendor.phone"
            :vendorCategory="vendor.WHT ? vendor.WHT.category : 'N/A'"
          />
        </v-col>
      </v-row>

      <!-- display a no data info if there are no data -->
      <div class="d-flex justify-center align-center pt-6" v-else>
        <span class="text--primary text-h5 font-monospace"
          >No Data available</span
        >
      </div>
      <!-- pagination component -->
      <div
        class="pagination__container px-8"
        v-if="filteredVendors && filteredVendors.length > 0"
      >
        <BasePagination
          :current-page="currentPage"
          :visible-pages="visiblePageValue"
          :total-pages="totalPages"
          @page-changed="handlePageChange"
        />
      </div>
    </v-skeleton-loader>
  </div>
</template>

<script>
import VendorTable from "./VendorTable.vue";
import vendorTableCard from "./vendorTableCard.vue";
import { mapActions, mapGetters } from "vuex";
import BasePagination from "../BasePagination.vue";
import pagination from "@/mixins/pagination";
export default {
  name: "vendorsContact",
  components: {
    VendorTable,
    vendorTableCard,
    BasePagination,
  },
  mixins: [pagination],
  data() {
    return {
      dialog: false,
      //vendors: [],
      searchQuery: "",
      itemsPerPage: 10,
      loading: false,
    };
  },
  methods: {
    ...mapActions({ showToast: "ui/showToast" }),
    closeModal() {
      this.dialog = false;
      // this.workflowName = e;
      // console.log(e);
    },
    onDroppedFiles($event) {
      this.dragging = false;
      let files = [...$event.dataTransfer.items]
        .filter((item) => item.kind === "file")
        .map((item) => item.getAsFile());

      console.table(files);
    },
    setSearchText(value) {
      this.searchQuery = value;
    },
    alertCSVDownload() {
      this.showToast({
        sclass: "success",
        show: true,
        message: "CSV downloaded!",
        timeout: 3000,
      });
    },
    //fetch all vendors
    async fetchVendors() {
      this.loading = true;
      try {
        await this.$store.dispatch("contacts/fetchAllVendors");
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
  },

  computed: {
    ...mapGetters({
      vendors: "contacts/getAllVendors",
      vendorCompany: "contacts/getVendorsCompanyName",
      combineCustAndVend: "contacts/getVendorAndCustomerCompanyName",
    }),
    filteredVendors() {
      if (this.searchQuery) {
        return this.vendors.filter((vendor) => {
          return (
            vendor.bankaccount
              .toLowerCase()
              .match(this.searchQuery.toLowerCase()) ||
            vendor.email.toLowerCase().match(this.searchQuery.toLowerCase()) ||
            vendor.vendorname
              .toLowerCase()
              .match(this.searchQuery.toLowerCase())
          );
        });
      } else return this.vendors;
    },
    // get the length of the filtered form array to use it as the value of the visible page
    visiblePageValue() {
      const pageNumber = Math.floor(
        this.filteredVendors.length / this.itemsPerPage
      );
      return pageNumber > 5 ? 5 : pageNumber < 1 ? 1 : pageNumber;
    },

    // get array per page
    displayedItems() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      if (
        this.filteredVendors &&
        this.filteredVendors.length > this.itemsPerPage
      ) {
        return this.filteredVendors.slice(startIndex, endIndex);
      } else {
        return this.filteredVendors.slice();
      }
    },
    totalPages() {
      return Math.ceil(this.filteredVendors.length / this.itemsPerPage);
    },
  },

  mounted() {
    this.fetchVendors();
  },
};
</script>

<style scoped>
.switch-card {
  height: 53px;
  background: rgba(127, 145, 155, 0.052607);
}

.switch {
  width: 15.28px;
  height: 15.28px;
}

.export-btn {
  font-family: "Inter" sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  letter-spacing: 0.636364px;

  color: #19283d;
}

i.sli-font {
  font-size: 12px !important;
  display: inline-block !important;
}

.material-icons {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  color: #19283d;
}

.pagination__container {
  display: flex;
  justify-content: right;
  align-items: center;
  padding-top: 6px;
}
</style>
